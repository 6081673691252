'use strict';

const Breadcrumb =  {
    el:null,
    init: function(){
        if (window.matchMedia('(max-width: 1023px)').matches) {
            Breadcrumb.el = document.querySelector('.c-breadcrumb');
            if(Breadcrumb.el)
                Breadcrumb.event();
        }
    },
    event: function() {
        if(Breadcrumb.el.querySelector('.c-breadcrumb__link'))
            Breadcrumb.el.querySelector('.c-breadcrumb__link').addEventListener('click', (e) => {
                e.preventDefault();
                Breadcrumb.el.classList.add('extended--');
            })
    }
};

export default Breadcrumb;
