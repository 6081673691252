"use strict";

const Accordion = {
  elts: null,
  
  init: function () {
    Accordion.elts = document.querySelectorAll(".f-accordion");
    if (Accordion.elts && Accordion.elts.length > 0) {
      Accordion.elts.forEach((elt) => {
        const toggleButtons = elt.querySelectorAll(".toggle-button");
        if (toggleButtons && toggleButtons.length > 0) {
          toggleButtons.forEach((button) => {
            button.addEventListener("click", () => {
              const toggleContent =button.parentElement.querySelector(".toggle-content");
              if (toggleContent) {
                // if (!window.matchMedia('(max-width: 1023px)').matches) {
                //   if (button !== elt.querySelector(".toggle-button.active")) {
                //     Array.from(elt.querySelectorAll(".active")).map((el) => {
                //       el.classList.remove("active");
                //       if (el.classList.contains("toggle-button")) {
                //         el.setAttribute("aria-expanded", false);
                //       }
                //     });
                //   }
                // }
                if (button.classList.contains("active")) {
                  button.classList.remove("active");
                  toggleContent.classList.remove("active");
                  button.setAttribute("aria-expanded", false);
                } else {
                  button.classList.add("active");
                  toggleContent.classList.add("active");
                  button.setAttribute("aria-expanded", true);
                }
              }
            });
          });
        }
      });
    }
  },
};

export default Accordion;
