'use strict';

const HeaderHP = {
    init: function () {
        const el = document.querySelector('.headerHp__video');
        if (el) {
            const cta = el.querySelector('.c-video__player-cta');
            if (cta) {
                const buttonPlay = cta.querySelector('.c-button.player--');
                const video = el.querySelector('video');

                cta.addEventListener('click', () => {
                    this.togglePlayPause(video, buttonPlay);
                });
            }
        }
    },
    togglePlayPause: function (video, buttonPlay) {
        const playLabel = buttonPlay.getAttribute('data-play-button');
        const pauseLabel = buttonPlay.getAttribute('data-pause-button');
        const ariaLabel = buttonPlay.querySelector('span');
        if (video.paused) {
            video.play();
            buttonPlay.classList.add('isplaying--');
            buttonPlay.setAttribute('aria-pressed', 'true');
            buttonPlay.setAttribute('aria-label', pauseLabel);
            ariaLabel.textContent = pauseLabel;
        } else {
            video.pause();
            buttonPlay.classList.remove('isplaying--');
            buttonPlay.setAttribute('aria-pressed', 'false');
            buttonPlay.setAttribute('aria-label', playLabel);
            ariaLabel.textContent = playLabel;
        }
    }
};

export default HeaderHP;