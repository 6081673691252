"use strict";

const Video = {
  els: null,
  init: function () {
    Video.els = document.querySelectorAll(".f-video");
    if (Video.els && Video.els.length > 0) {
      Video.els.forEach((el) => {
        Video.managePoster(el);
      });
    }
  },
  managePoster: function (el) {
    const poster = el.querySelector(".c-video__player-poster");
    if (poster) {
      const buttonPlay = el.querySelector(".c-video__play");
      buttonPlay.addEventListener("click", () => {
        Video.showPopin(el);
        buttonPlay.classList.add("isplaying--");
      });

      poster.addEventListener("click", () => {
        Video.showPopin(el);
        buttonPlay.classList.add("isplaying--");
      });

      const closeButton = el.querySelector(".c-popin__close");
      if (closeButton) {
        closeButton.addEventListener("click", () => {
          Video.hidePopin(el);
          buttonPlay.classList.remove("isplaying--");
        });
      }

      const overlayPopin = el.querySelector(".c-popin__overlay");
      if (overlayPopin) {
        overlayPopin.addEventListener("click", () => {
          Video.hidePopin(el);
          buttonPlay.classList.remove("isplaying--");
        });
      }
    }
  },
  showPopin: function (el) {
    const popin = el.querySelector(".c-popin");
    if (popin) {
      popin.classList.add("show--");
      Video.playVideo(el);
      document.addEventListener("keydown", (e) => Video.trapFocus(el, e));
    }
  },
  hidePopin: function (el) {
    const popin = el.querySelector(".c-popin");
    if (popin) {
      popin.classList.remove("show--");
      Video.stopVideo(el);
      document.removeEventListener("keydown", (e) => Video.trapFocus(el, e));
    }
  },
  playVideo: function (el) {
    if (tarteaucitron && tarteaucitron.state.youtube) {
      let src = el
        .querySelector("iframe")
        .getAttribute("src")
        .replace("&autoplay=0", "")
        .replace("&muted=0", "")
        .replace("&playsinline=0", "")
        .replace("?autoplay=0", "")
        .replace("?muted=0", "")
        .replace("?playsinline=0", "");
      if (src.includes("?")) {
        src += "&autoplay=1&playsinline=1";
      } else {
        src += "?autoplay=1&playsinline=1";
      }
      el.querySelector(".youtube_player").innerHTML = `
                <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="${src}"></iframe>`;
    }
  },
  stopVideo: function (el) {
    const iframe = el.querySelector("iframe");
    if (iframe) {
      let src = iframe.getAttribute("src");
      src = src
        .replace(/[\?&]autoplay=[0-1]/, "")
        .replace(/[\?&]muted=[0-1]/, "")
        .replace(/[\?&]playsinline=[0-1]/, "")
        .replace(/[&?]$/, "");
      iframe.setAttribute("src", src);
    }
  },
  trapFocus: function (el, e) {
    const firstFocusableElement = el.querySelector(".c-popin__close");
    const lastFocusableElement = el.querySelector(".c-popin__focusTrap")
    
    let isTabPressed = e.key === "Tab" || e.keyCode === 9;
    
    if (!isTabPressed) return;
    
    if (e.shiftKey && document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus()
        e.preventDefault() 
    }

    if(!e.shiftKey && document.activeElement === lastFocusableElement){
        firstFocusableElement.focus()
        e.preventDefault()
    }
  },
};

export default Video;
