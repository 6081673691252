"use strict";

const SliderVideos = {
  els: [],
  init: function () {
    SliderVideos.els = document.querySelectorAll(".f-slider.video--");
    if (SliderVideos.els.length) {
      SliderVideos.els.forEach((slider) => {
        const videos = slider.querySelectorAll(".c-video");
        const popin = slider.querySelector(".c-popin");

        videos.forEach((video) => {
          SliderVideos.managePoster(video, popin);
        });
      });
    }
  },
  managePoster: function (video, popin) {
    const poster = video.querySelector(".c-video__player-poster");
    if (poster) {
      const buttonPlay = video.querySelector(".c-video__play");
      buttonPlay.addEventListener("click", () => {
        SliderVideos.showPopin(video, popin);
        buttonPlay.classList.add("isplaying--");
      });

      poster.addEventListener("click", () => {
        SliderVideos.showPopin(video, popin);
        buttonPlay.classList.add("isplaying--");
      });

      const closeButton = popin.querySelector(".c-popin__close");
      if (closeButton) {
        closeButton.addEventListener("click", () => {
          SliderVideos.hidePopin(popin);
          buttonPlay.classList.remove("isplaying--");
        });
      }

      const overlayPopin = popin.querySelector(".c-popin__overlay");
      if (overlayPopin) {
        overlayPopin.addEventListener("click", () => {
          SliderVideos.hidePopin(popin);
          buttonPlay.classList.remove("isplaying--");
        });
      }
    }
  },
  showPopin: function (video, popin) {
    if (popin) {
      popin.classList.add("show--");
      SliderVideos.playVideo(video, popin);
      document.addEventListener("keydown", (e) => SliderVideos.trapFocus(popin, e));
    }
  },
  hidePopin: function (popin) {
    if (popin) {
      popin.classList.remove("show--");
      SliderVideos.stopVideo(popin);
      document.removeEventListener("keydown", (e) => SliderVideos.trapFocus(popin, e));
    }
  },
  playVideo: function (video, popin) {
    if (tarteaucitron && tarteaucitron.state.youtube) {
      const id = video.getAttribute("data-id");
      const title = video.getAttribute("data-title");
      const url = `//www.youtube-nocookie.com/embed/${id}?theme=dark&rel=0&controls=1&showinfo=0&mute=0&autoplay=1&playsinline=1`;
      popin.querySelector(".figure").setAttribute("aria-label", title);
      popin.querySelector(
        ".youtube_player"
      ).innerHTML = `<iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="${url}"></iframe>`;
    }
  },
  stopVideo: function (popin) {
    const iframe = popin.querySelector("iframe");
    if (iframe) {
      let src = iframe.getAttribute("src");
      src = src
        .replace(/[\?&]autoplay=[0-1]/, "")
        .replace(/[\?&]muted=[0-1]/, "")
        .replace(/[\?&]playsinline=[0-1]/, "")
        .replace(/[&?]$/, "");
      iframe.setAttribute("src", src);
    }
  },
  trapFocus: function (popin, e) {
    const firstFocusableElement = popin.querySelector(".c-popin__close");
    const lastFocusableElement = popin.querySelector(".c-popin__focusTrap")
    
    let isTabPressed = e.key === "Tab" || e.keyCode === 9;
    
    if (!isTabPressed) return;
    
    if (e.shiftKey && document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus()
        e.preventDefault() 
    }

    if(!e.shiftKey && document.activeElement === lastFocusableElement){
        firstFocusableElement.focus()
        e.preventDefault()
    }
  },
};

export default SliderVideos;
