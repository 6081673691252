'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

const HubMedias = {
    els: null,
    init: function () {
        HubMedias.els = document.querySelectorAll('.headerMedias');
        if (HubMedias.els && HubMedias.els.length > 0) {
            HubMedias.els.forEach(el => {
                HubMedias.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        let navigation = null;
        swiper = new Swiper(slider, {
            modules: [Navigation, A11y],
            a11y: true,
            slidesPerView: "auto",
            loop: true,
            speed: 600,
            autoHeight:false,
            mousewheel: {
                releaseOnEdges: false,
                forceToAxis:true
            },
            keyboard: {
                enabled: true,
            },
            navigation: {
                prevEl: ".f-slider__navigation-prev",
                nextEl: ".f-slider__navigation-next",
            }
        });

    },
};

export default HubMedias;