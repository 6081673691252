'use strict';

// core version + modules:
import Swiper, {Navigation, Pagination, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';

const PushChildrenHub = {
    els: null,
    init: function () {
        PushChildrenHub.els = document.querySelectorAll('.hubChild');
        if (PushChildrenHub.els && PushChildrenHub.els.length > 0) {
            PushChildrenHub.els.forEach(el => {
                PushChildrenHub.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;
        let spaceBetween = 70 - 16
        // if (window.matchMedia('(max-width: 841px)').matches) {
        //     spaceBetween = 34
        // }
        // else if(window.matchMedia('(max-width: 1320px)').matches) {
        //     spaceBetween = 40
        // }
        // else if(window.matchMedia('(max-width: 1512px)').matches) {
        //     spaceBetween = 60
        // }

        let modules = "";
        let navigation = null;
        let pagination = null;
        swiper = new Swiper(slider, {
            modules: [Navigation, Pagination, A11y],
            a11y: true,
            slidesPerView: "auto",
            loop: false,
            spaceBetween:spaceBetween,
            speed: 600,
            autoHeight:false,
            navigation: {
                prevEl: ".f-slider__navigation-prev",
                nextEl: ".f-slider__navigation-next",
            },
            pagination: {
                el: ".f-slider__pagination",
                clickable: true,
                bulletClass: `f-slider__pagination-bullet swiper-pagination-bullet`
            }
        });

    },
};

export default PushChildrenHub;