'use strict';
const axios = require('axios');

const Search = {
    formSearch:null,
    inputSearch: null,
    typeList: null,
    loader: null,
    loadMore: null,
    result: null,
    searchSelected: '',
    postTypesSelected: '',
    numSearch:'',
    currentPage: 1,
    postPerPage: 5,
    apiUrl: null,
    init: function () {
        if (document.querySelector('.search')) {
            Search.apiUrl = apiData['search'];
            if (Search.apiUrl) {
                Search.formSearch = document.querySelector('.search__form');
                Search.inputSearch = document.querySelector('.search__input input');
                Search.typeList = document.querySelector('.search__list');
                Search.loader = document.querySelector('.search__results-loader');
                Search.loadMore = document.querySelector('.search__results-loadMore');
                Search.result = document.querySelector('.search__results-content');
                Search.numSearch = document.querySelector('.search__results-number');

                Search.events();
            } else {
                console.log("err get api");
            }
        }
    },
    events: function () {
        if(Search.inputSearch) {
            Search.inputSearch.addEventListener('input', () => {
                if (Search.inputSearch.value.length >= 3) {
                    Search.formSearch.querySelector('.c-button.submit--').removeAttribute('disabled');
                } else {
                    Search.formSearch.querySelector('.c-button.submit--').setAttribute('disabled', true);
                }
            })
        }
        if (Search.formSearch) {
            Search.formSearch.addEventListener('submit', (e) => { //Au submit du form de recherche
                e.preventDefault();
                Search.postTypesSelected = "";
                Search.typeList.querySelectorAll('button').forEach(button => {
                    if(button.getAttribute('data-type') === "") {
                        button.classList.add('current--');
                        button.setAttribute('aria-current', 'true');
                    }
                    else {
                        button.classList.remove('current--');
                        button.removeAttribute('aria-current');
                    }
                    
                });
                Search.searchSelected = Search.inputSearch.value;
                Search.fetchSearch();
                Search.formSearch.classList.add("disabled--");
                Search.inputSearch.blur();
            });
        }

        if (Search.loadMore) {
            Search.loadMore.addEventListener('click', (e) => { //clic sur le loadmore
                e.preventDefault();
                Search.loadMore.classList.remove('show--');
                Search.currentPage += 1;
                Search.fetchSearch();
            });
        }

        Search.typeList.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                Search.changeType(button);
            });
        });

    },
    resetAllParams: function() {
        Search.currentPage = 1;
        Search.result.innerHTML="";
        Search.loadMore.classList.remove('show--');
        Search.typeList.classList.add('hide--');
        Search.numSearch.classList.add('hide--')
        Search.numSearch.querySelector('.number').innerHTML = "0";
        Search.numSearch.querySelector('.searchValue').innerHTML = Search.searchSelected;
        Search.fetchSearch();
    },
    changeType: function (button) {
        Search.typeList.querySelectorAll('button').forEach(btn => {
            btn.classList.remove('current--'); 
            btn.removeAttribute('aria-current');
        });
    
        button.classList.add('current--'); 
        button.setAttribute('aria-current', 'true');
    
        Search.postTypesSelected = button.getAttribute('data-type');
        Search.resetAllParams();
    },
    fetchSearch: function () {
        Search.loader.classList.add('show--');
        if(Search.currentPage === 1) {
            Search.result.innerHTML="";
        }
        axios({
            method: "get",
            url: `${Search.apiUrl}&posts_per_page=${Search.postPerPage}&text_search=${Search.searchSelected}&post_types[]=${Search.postTypesSelected}&page=${Search.currentPage}`,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
            //handle success
            if (response.status === 200) {
                if (response.data && response.data.total > 0) {
                    if (response.data.totalPages > Search.currentPage) {
                        Search.loadMore.classList.add('show--');
                    } else {
                        Search.loadMore.classList.remove('show--');
                    }
                    Search.numSearch.querySelector('.number').innerHTML = response.data.total;
                    Search.numSearch.querySelector('.searchValue').innerHTML = Search.searchSelected;
                    Search.numSearch.classList.remove('hide--')
                    let html = '';
                    response.data.items.forEach((elt) => {
                        // console.log(elt)
                        html+=` <a href="${elt.link.url}" class="c-button text--" title="${elt.link.title}" target="${elt.link.target}">
                                    <div class="c-push horizontal-- large--">
                                        <div class="c-push__content">
                                            <h3 class="c-push__title underline--">${elt.title}</h3>
                                            ${elt.date ?
                                                (elt.end_date && elt.date.month === elt.end_date.month && elt.date.year === elt.end_date.year) ?
                                                    `<p class="c-push__date c-date small--">
                                                        <span class="c-date__day">${elt.date.day} - ${elt.end_date.day} ${elt.date.month}</span>
                                                        <span class="c-date__year">${elt.date.year}</span>
                                                    </p>`
                                                    :
                                                    `<p class="c-push__date c-date small--">
                                                        <span class="c-date__day">${elt.end_date ? `${elt.date.day} ${elt.date.month}<br />> ${elt.end_date.day} ${elt.end_date.month}` : `${elt.date.day} ${elt.date.month}`}</span>
                                                        <span class="c-date__year">${elt.end_date ? elt.end_date.year : elt.date.year}</span>
                                                    </p>`
                                                :
                                                '<p class="c-push__date c-date small--"></p>'
                                            }
                                            ${elt.post_type === "event" ?
                                                elt.location ? `<div class="c-push__location"><i class="icon-pin2" aria-hidden="true"></i><p>${elt.location.city ? elt.location.city : ''} ${elt.location.country ? `<br /><span>${elt.location.country}</span>`: ''}</p></div>`:''
                                            :
                                                elt.excerpt ? `<div class="c-push__excerpt"><p>${elt.excerpt}</p></div>`: ''
                                            }
                                            <div class="c-push__link" aria-hidden="true">
                                                ${elt.post_type === 'press_release' ? `<div class="c-button__elt"><span>${elt.link.title}</span><i class="icon-download" aria-hidden="true"></i></div>` : `<div class="c-button__elt"><span>${elt.link.title}</span><i class="icon-arrow" aria-hidden="true"></i></div>` }
                                            </div>
                                        </div>
                                        <div class="c-push__img c-img">
                                            <div class="figure">
                                                <img src="${elt.image.url}" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </a>`;
                    })
                    Search.result.insertAdjacentHTML('beforeend', html);
                } else {
                    // console.log(Search.result.getAttribute('data-noresult'))
                    Search.result.innerHTML = `<p>${Search.result.getAttribute('data-noresult')}</p>`;
                    Search.numSearch.classList.add('hide--');
                    Search.numSearch.querySelector('.number').innerHTML = "0";
                    Search.numSearch.querySelector('.searchValue').innerHTML = Search.searchSelected;
                    Search.loadMore.classList.remove('show--');
                }
                Search.typeList.classList.remove('hide--');
            } else {
                console.log('Error:', response.statusText);
            }
            Search.loader.classList.remove('show--');
            Search.formSearch.classList.remove("disabled--");
        }).catch(function (error) {
            console.log('err', error);
            Search.loader.classList.remove('show--');
            Search.formSearch.classList.remove("disabled--");
        });
    }
};

export default Search;