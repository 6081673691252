'use strict';

const GeoAeraMap = {
    elts: null,
    init: function () {
        GeoAeraMap.elts = document.querySelectorAll('.f-geoAeraMap');
        if(GeoAeraMap.elts && GeoAeraMap.elts.length > 0) {
            
            GeoAeraMap.elts.forEach(elt => {
                if (window.matchMedia('(max-width: 1023px)').matches) {
                    GeoAeraMap.accordeon(elt);
                }
                GeoAeraMap.tab(elt);
            });
        }
    },
    accordeon: function(elt) {
        elt.querySelector('.f-geoAeraMap__title').addEventListener('click', function () {
            elt.classList.toggle('open--');
        });
    },
    tab: function (elt) {
        const buttons = elt.querySelectorAll('.c-button.list--');
        const panels = elt.querySelectorAll('.f-geoAeraMap__panel-content');

        buttons.forEach((button, index) => {
            // Activer l'onglet au clique dessus
            button.addEventListener('click', function () {
                GeoAeraMap.activateTab(elt, button, panels, index);
            });

            // Activer l'onglet en naviguant avec le clavier (au focus)
            button.addEventListener('focus', function () {
                GeoAeraMap.activateTab(elt, button, panels, index);
            });

            // Gestion de la navigation au clavier avec les flèches et tab
            button.addEventListener('keydown', function (e) {
                const key = e.key;

                if (key === 'ArrowRight' || key === 'ArrowDown') {
                    e.preventDefault();
                    const newIndex = (index + 1) % buttons.length;
                    buttons[newIndex].focus();
                }

                if (key === 'ArrowLeft' || key === 'ArrowUp') {
                    e.preventDefault();
                    const newIndex = (index - 1 + buttons.length) % buttons.length;
                    buttons[newIndex].focus();
                }

                
                if (key === 'Enter' || key === ' ') {
                    e.preventDefault();
                    GeoAeraMap.activateTab(elt, button, panels, index);
                }

                if (key === 'Tab' && !e.shiftKey) {
                    const panel = elt.querySelector(`#${button.getAttribute('data-item')}`);
                    const focusableElements = GeoAeraMap.getFocusableElements(panel);

                    if (focusableElements.length > 0) {
                        focusableElements[0].focus();
                        e.preventDefault();
                    }
                }
            });
        });

        panels.forEach((panel, index) => {
            panel.addEventListener('keydown', function (e) {
                const key = e.key;
                const focusableElements = GeoAeraMap.getFocusableElements(panel);
                const lastFocusableElement = focusableElements[focusableElements.length - 1];

                if (key === 'Tab' || key === 'ArrowDown' || key === 'ArrowRight') {
                    if (document.activeElement === lastFocusableElement && index === panels.length - 1) {
                        // Dernier lien du dernier panneau  => on va au module suivant
                        return;
                    }

                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        const nextTabIndex = index + 1;
                        buttons[nextTabIndex].focus();
                    }
                }

                if (key === 'ArrowUp' || key === 'ArrowLeft') {
                    const firstFocusableElement = focusableElements[0];
                    if (document.activeElement === firstFocusableElement && index === 0) {
                        // Si on est sur le premier élément du premier panneau, focus sur le dernier onglet
                        e.preventDefault();
                        buttons[buttons.length - 1].focus();
                    }
                }
            });
        });
    },
    getFocusableElements: function (panel) {
        // liste des éléments pouvant être focusables
        return panel.querySelectorAll('a, button');
    },
    activateTab: function (elt, button, panels, index) {
        const itemId = button.getAttribute('data-item');
        const panel = elt.querySelector(`#${itemId}`);
        const buttons = elt.querySelectorAll('.c-button.list--');

        // Mise à jour des onglets
        buttons.forEach(btn => {
            if (btn === button) {
                btn.classList.add('current--');
                btn.setAttribute('aria-selected', 'true');
            } else {
                btn.classList.remove('current--');
                btn.setAttribute('aria-selected', 'false');
            }
        });

        // Mise à jour des panneaux
        panels.forEach(otherPanel => {
            if (otherPanel === panel) {
                otherPanel.classList.add('show--');
                otherPanel.setAttribute('aria-selected', 'true');
                otherPanel.setAttribute('tabindex', '0');  // Rendre le panneau focusable
            } else {
                otherPanel.classList.remove('show--');
                otherPanel.setAttribute('aria-selected', 'false');
                otherPanel.setAttribute('tabindex', '-1');  // Retirer le focus des autres panneaux
            }
        });

        // Gestion du scroll en mobile
        if (window.matchMedia('(max-width: 1023px)').matches) {
            panel.closest('.f-geoAeraMap__body').querySelector('.f-geoAeraMap__panel').scrollIntoView({ behavior: 'smooth' });
        }
    }
};

export default GeoAeraMap;