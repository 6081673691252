"use strict";

const Header = {
    el: null,
    toggle: null,
    lang: null,
    langCta: null,
    overlay: null,
    nav: null,
    contactElt: null,
    currentSubmenu: null,
    init: function () {
        Header.el = document.querySelector(".header");
        Header.overlay = document.querySelector(".overlay");
        Header.lang = Header.el.querySelector(".header__languages");
		Header.nav = Header.el.querySelector(".header__navBurger");
		Header.toggle = Header.el.querySelector(".header__toggle");

        if (Header.el && Header.lang && Header.nav) {
            Header.langCta = Header.lang.querySelector(".header__languages-current");
            //Menu toggle
            if (Header.toggle) {
                Header.toggle.addEventListener("click", () => {
                    Header.toggle.setAttribute("aria-expanded", "true");
                    Header.el.classList.toggle("navOpened");
                    Header.lang.classList.remove("open--");
                    Header.nav.setAttribute("aria-hidden", false);

                    if (!Header.el.classList.contains("navOpened")) {
                        Header.currentSubmenu = null;
                    }

                    if (Header.overlay) {
                        if (Header.el.classList.contains("navOpened")) {
                            Header.overlay.classList.add("show--");
                            document.body.classList.add("no-scroll");
                        } else {
                            Header.overlay.classList.remove("show--");

                            Header.toggle.setAttribute("aria-expanded", "false");
                            const timer = setTimeout(() => {
                                if (Header.nav.querySelector(".subMenu.show--")) {
                                    Header.nav.querySelector(".subMenu.show--").classList.remove("show--");
                                    Header.nav.querySelector(".subMenu.show--").classList.remove("invisible--");
                                }
                                clearTimeout(timer);
                            }, 200);
                            Header.nav.setAttribute("aria-hidden", true);
                            document.body.classList.remove("no-scroll");
                        }
                    }
                });

				//accessibilité => lien d'evitement
				const toggleSec = document.querySelector(".c-linkFocusable__menuBtn");
				if (toggleSec) {
					toggleSec.addEventListener("click", () => {
						Header.toggle.click();
					});
				}
            }
			

            //Au clic en dehors de la zone de menu
            document.addEventListener("click", (event) => {
				if (Header.el.classList.contains("navOpened") && !Header.nav.contains(event.target) && !Header.toggle.contains(event.target) && !event.target.classList.contains("c-linkFocusable__menuBtn")) {
					Header.menuClose();
				}
			});

			// Au focus en dehors du menu
			Header.nav.addEventListener("focusout", (event) => {
				const isStillInMenu = Header.nav.contains(event.relatedTarget) || Header.toggle.contains(event.relatedTarget);
				if (!isStillInMenu) {
					Header.menuClose();
				}
			});

            //Switcher de langue
            Header.switcherLanguage();

            //construction du markup du menu burger
            Header.menuConstructor();
        }

		//CTA contact sticky
		Header.contactElt = document.querySelector(".c-contact");
        if (document.querySelector(".c-contact")) {
            Header.contactSticky();
            Header.contactEvent();
        }
    },
	menuClose: function() {
		Header.el.classList.remove("navOpened");
        Header.overlay.classList.remove("show--");
        Header.nav.setAttribute("aria-hidden", true);
        document.body.classList.remove("no-scroll");
        if (Header.toggle) {
            Header.toggle.setAttribute("aria-expanded", "false");
        }
        const timer = setTimeout(() => {
            if (Header.nav.querySelector(".subMenu.show--"))
                Header.nav.querySelector(".subMenu.show--").classList.remove("show--");
            clearTimeout(timer);
        }, 200);
	},
    menuConstructor: function () {
        const mainNav = Header.nav.querySelectorAll(".menu-item-has-children");
        mainNav.forEach((elt) => {
            const submenuContent = elt.querySelector(".subMenu__ctn");
            const subMenuList = elt.querySelector(".sub-menu");
			const subMenu = elt.querySelector(".subMenu")
			const parentLink = elt.querySelector("a");

            if (submenuContent && subMenuList) {
                submenuContent.appendChild(subMenuList);
            }
            //Au clic sur un sous menu
            elt.querySelector("a").addEventListener("click", (e) => {
                e.preventDefault();
                elt.querySelector(".subMenu").classList.add("show--");
                elt.querySelector(".subMenu").classList.remove("invisible--");
                Header.currentSubmenu = elt.querySelector(".subMenu");
                Header.nav.classList.add("subMenuOpened--");
				if (parentLink) {
					parentLink.setAttribute("aria-expanded", "true");
				}
            	subMenu.setAttribute("aria-hidden", "false");
            });
            //au clic sur le bouton back d'un sous menu
            elt.querySelector(".subMenu__btnback button").addEventListener("click", () => {
				elt.querySelector(".subMenu").classList.remove("show--");
				Header.currentSubmenu = null;
				Header.nav.classList.remove("subMenuOpened--");
				subMenu.setAttribute("aria-hidden", "true");

				
				if (parentLink) {
					parentLink.setAttribute("aria-expanded", "false");
					parentLink.focus(); // Remettre le focus sur le lien <a> parent
				}
				
				const timer = setTimeout(() => {
					elt.querySelector(".subMenu").classList.add(
						"invisible--"
					);
					clearTimeout(timer);
				}, 300);
			});
        });
    },
    switcherLanguage: function () {
        //Au clic sur le cta Switcher de langue
        Header.langCta.addEventListener("click", () => {
            Header.lang.classList.toggle("open--");
            Header.el.classList.remove("navOpened");

            const isOpen = Header.lang.classList.contains("open--");
            Header.langCta.setAttribute("aria-expanded", isOpen);
            Header.lang.querySelector(".header__languages-option").setAttribute("aria-hidden", !isOpen);

            if (Header.overlay) {
                if (window.matchMedia("(max-width: 1023px)").matches) {
                    if (isOpen) {
                        Header.overlay.classList.add("show--");
                        document.body.classList.add("no-scroll");
                    } else {
                        Header.overlay.classList.remove("show--");
                        document.body.classList.remove("no-scroll");
                    }
                }
            }
        });

        //Au clic en dehors de la zone de language
        document.addEventListener("click", (event) => {
            if (
                Header.lang.classList.contains("open--") &&
                !Header.lang.contains(event.target)
            ) {
                Header.overlay.classList.remove("show--");
                document.body.classList.remove("no-scroll");
                Header.closeSwitcherLanguage();
            }
        });
    },
    closeSwitcherLanguage: function () {
        Header.lang.classList.remove("open--");
        Header.langCta.setAttribute("aria-expanded", "false");
        Header.lang.querySelector(".header__languages-option").setAttribute("aria-hidden", "true");
    },
    contactSticky: function () {
        window.addEventListener("scroll", function () {
            const footerRect = document
                .querySelector(".footer")
                .getBoundingClientRect();
            const contactRect = Header.contactElt.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            // console.log(footerRect.top, contactRect.height)
            if (footerRect.top <= windowHeight - contactRect.height + 100) {
                Header.contactElt.classList.add("fixed--");
                Header.contactElt.style.bottom = `${
                    windowHeight - footerRect.top
                }px`;
            } else {
                Header.contactElt.style.position = "fixed";
                Header.contactElt.classList.remove("fixed--");
                Header.contactElt.style.bottom = `0px`;
            }
        });
    },
    contactEvent: function () {
        const btn = Header.contactElt.querySelector("button");
        const contactExtended = Header.contactElt.querySelector(".c-contact__extended");

        Header.contactElt.querySelector("button").addEventListener("click", (e) => {
                e.preventDefault();
                if (!Header.contactElt.classList.contains("open--")) {
                    Header.openContact(btn, contactExtended);
                } else {
                    Header.closeContact(btn, contactExtended);
                }
            });
        //Au clic en dehors de la zone de contact
        document.addEventListener("click", (event) => {
            if (Header.contactElt.classList.contains("open--") && !Header.contactElt.contains(event.target)) {
                Header.closeContact(btn, contactExtended);
            }
        });

        //accessibilité
        document.addEventListener("focus",(event) => {
			const focusedElement = event.target;
			if (Header.contactElt.classList.contains("open--") && !Header.contactElt.contains(focusedElement)) {
				Header.closeContact(btn, contactExtended);
			}
		}, true);
    },
    openContact: function (btn, contactExtended) {
        Header.contactElt.classList.add("open--");
        btn.setAttribute("aria-expanded", "true");
        contactExtended.setAttribute("aria-hidden", "false");
        Header.overlay?.classList.add("show--");
        document.body.classList.add("no-scroll");
        contactExtended.focus(); // Déplacer le focus sur le contenu déroulé
    },
    closeContact: function (btn, contactExtended) {
        Header.contactElt.classList.remove("open--");
        btn.setAttribute("aria-expanded", "false");
        contactExtended.setAttribute("aria-hidden", "true");
        const timer = setTimeout(() => {
            Header.overlay.classList.remove("show--");
            document.body.classList.remove("no-scroll");
            clearTimeout(timer);
        }, 300);
    },
};

export default Header;
