'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

const KeyFigures = {
    els: null,
    init: function () {
        KeyFigures.els = document.querySelectorAll('.f-keyfigures');
        if (KeyFigures.els && KeyFigures.els.length > 0) {
            KeyFigures.els.forEach(el => {
                KeyFigures.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        let navigation = null;
        let scrollbar = null;
        swiper = new Swiper(slider, {
            modules: [Navigation, Scrollbar, A11y],
            a11y: true,
            slidesPerView: "auto",
            loop: false,
            speed: 600,
            autoHeight:false,
            navigation: {
                prevEl: ".f-slider__navigation-prev",
                nextEl: ".f-slider__navigation-next",
            },
            scrollbar: {
                el: ".f-slider__scrollbar",
                hide: false,
                draggable: true
            },
            on: {
                init: function () {
                    slider.setAttribute('aria-roledescription', 'carousel');
                    slider.setAttribute('role', 'region');
                    slider.setAttribute('aria-label', 'Key figures slider');
                    
                    // Rendre chaque slide focusable
                    const slides = slider.querySelectorAll('.swiper-slide');
                    slides.forEach((slide, index) => {
                        slide.setAttribute('tabindex', '0');
                        slide.setAttribute('aria-label', `Slide ${index + 1} of ${slides.length}`);
                    });
                }
            }
        });

       
    },
};

export default KeyFigures;