'use strict';

// core version + modules:

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Swiper, {A11y, Navigation, Pagination} from 'swiper';

// Import Swiper styles





const SliderMulti = {
    els: null,
    init: function () {
        SliderMulti.els = document.querySelectorAll('.f-slider');
        if (SliderMulti.els && SliderMulti.els.length > 0) {
            SliderMulti.els.forEach(el => {
                SliderMulti.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        let navigation = null;
        let pagination = null;

        swiper = new Swiper(slider, {
            modules: [Navigation, Pagination, A11y],
            a11y: true,
            slidesPerView: "auto",
            loop: false,
            allowTouchMove: el.classList.contains('video--') ? false : true, 
            speed: 600,
            autoHeight:false,
            navigation: {
                prevEl: ".f-slider__navigation-prev",
                nextEl: ".f-slider__navigation-next",
            },
            pagination: {
                el: ".f-slider__pagination",
                clickable: true,
                bulletClass: `f-slider__pagination-bullet swiper-pagination-bullet`
            }
        });

    },
};

export default SliderMulti;