'use strict';
import Choices from 'choices.js';
const axios = require('axios');

const NewsList = {
    choicesSelect: null,
    selectDate: null,
    selectDateCurrent: null,
    typeList: null,
    loader: null,
    loadMore: null,
    result: null,
    dateSelected: '',
    postTypesSelected: '',
    currentPage: 1,
    postPerPage:5,
    apiUrl: null,
    init: function () {
        if (document.querySelector('.newsList')) {
            NewsList.apiUrl = apiData['news-list'];
            if (NewsList.apiUrl) {
                NewsList.selectDate = document.querySelector('.newsList__date');
                NewsList.selectDateCurrent = document.querySelector('.newsList__date-active');
                NewsList.typeList = document.querySelectorAll('.newsList__list button');
                NewsList.loader = document.querySelector('.newsList__results-loader');
                NewsList.loadMore = document.querySelector('.newsList__results-loadMore');
                NewsList.result = document.querySelector('.newsList__results-content');

                this.choicesSelect = new Choices(this.selectDate, { searchEnabled: false, itemSelectText: '', shouldSort: false });
                NewsList.postTypesSelected = document.querySelector('.newsList__list button.current--').getAttribute('data-type')
                NewsList.events();
                NewsList.fetchNews();
            } else {
                console.log("err get api");
            }
        }
    },
    events: function () {

        if (NewsList.selectDate) {
            NewsList.selectDate.addEventListener('change', (e) => { //Au changement de la date
                e.preventDefault();
                NewsList.changeDate(e);
            });
        }

        if (NewsList.selectDateCurrent) {
            NewsList.selectDateCurrent.addEventListener('click', (e) => { //A la suppression de la date
                e.preventDefault();
                NewsList.resetDate();
            });
        }

        if (NewsList.loadMore) {
            NewsList.loadMore.addEventListener('click', (e) => { //clic sur le loadmore
                e.preventDefault();
                NewsList.loadMore.classList.remove('show--');
                NewsList.currentPage += 1;
                NewsList.fetchNews();
            });
        }

        NewsList.typeList.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                NewsList.changeType(button);
            });
        });

    },
    changeDate: function (e) {
        const selectedYear = e.target.value;
        if (selectedYear !== "0") {
            NewsList.dateSelected = selectedYear;
            NewsList.selectDateCurrent.innerHTML = `<button type="button" class="c-button list-- current--">${selectedYear}<span></span></button>`;
            NewsList.choicesSelect.setChoiceByValue("0");
            NewsList.resetAllParams();
        }
    },
    resetDate: function () {
        NewsList.selectDateCurrent.innerHTML = '';
        NewsList.dateSelected = '';
        NewsList.choicesSelect.setChoiceByValue("0");
        NewsList.resetAllParams();
    },
    resetAllParams: function() {
        NewsList.currentPage = 1;
        NewsList.result.innerHTML="";
        NewsList.loadMore.classList.remove('show--');
        NewsList.fetchNews();
    },
    changeType: function (button) {

        NewsList.typeList.forEach(btn => btn.classList.remove('current--')); //remove les current

        button.classList.add('current--'); //add current sur l'item cliqué

        NewsList.postTypesSelected = button.getAttribute('data-type');
        NewsList.resetAllParams();
    },
    fetchNews: function () {
        NewsList.loader.classList.add('show--');
        const postTypes = NewsList.postTypesSelected.split(',');
        let postTypesParams = '';
        postTypes.forEach((type) => {
            postTypesParams += `&post_types[]=${type}`;
        });
        axios({
            method: "get",
            url: `${NewsList.apiUrl}&posts_per_page=${NewsList.postPerPage}&year=${NewsList.dateSelected}${postTypesParams}&page=${NewsList.currentPage}`,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
            //handle success
            if (response.status === 200) {
                if (response.data && response.data.total > 0) {
                    if (response.data.totalPages > NewsList.currentPage) {
                        NewsList.loadMore.classList.add('show--');
                    } else {
                        NewsList.loadMore.classList.remove('show--');
                    }
                    let html = '';
                    response.data.items.forEach((elt) => {
                        html+=` <a href="${elt.link.url}" class="c-button text--" title="${elt.link.title}" target="${elt.link.target}">
                                    <div class="c-push horizontal-- large--">
                                        <div class="c-push__img c-img">
                                            <div class="figure">
                                                <picture>
                                                    <img src="${elt.image.url}" alt="" />
                                                </picture>
                                            </div>
                                        </div>
                                        <div class="c-push__content">
                                            <h3 class="c-push__title underline--">${elt.title}</h3>
                                            ${elt.date ?
                                                (elt.end_date && elt.date.month === elt.end_date.month && elt.date.year === elt.end_date.year) ?
                                                    `<p class="c-push__date c-date small--">
                                                        <span class="c-date__day">${elt.date.day} - ${elt.end_date.day} ${elt.date.month}</span>
                                                        <span class="c-date__year">${elt.date.year}</span>
                                                    </p>`
                                                    :
                                                    `<p class="c-push__date c-date small--">
                                                        <span class="c-date__day">${elt.end_date ? `${elt.date.day} ${elt.date.month}<br />> ${elt.end_date.day} ${elt.end_date.month}` : `${elt.date.day} ${elt.date.month}`}</span>
                                                        <span class="c-date__year">${elt.end_date ? elt.end_date.year : elt.date.year}</span>
                                                    </p>`
                                                :
                                                '<p class="c-push__date c-date small--"></p>'
                                            }
                                            ${elt.post_type === "event" ?
                                                elt.location ? `<div class="c-push__location"><i class="icon-pin2" aria-hidden="true"></i><p>${elt.location.city ? elt.location.city : ''} ${elt.location.country ? `<br /><span>${elt.location.country}</span>`: ''}</p></div>`:''
                                            :
                                                elt.excerpt ? `<div class="c-push__excerpt"><p>${elt.excerpt}</p></div>`: ''
                                            }
                                            <div class="c-push__link">
                                                ${elt.post_type === 'press_release' ? `<div class="c-button__elt"><span>${elt.link.title}</span><i class="icon-download" aria-hidden="true"></i></div>` : `<div class="c-button__elt"><span>${elt.link.title}</span><i class="icon-arrow" aria-hidden="true"></i></div>` }
                                            </div>
                                        </div>
                                    </div>
                                </a>`;
                    })
                    NewsList.result.insertAdjacentHTML('beforeend', html);
                } else {
                    NewsList.result.innerHTML = NewsList.result.getAttribute('data-noresult');
                    NewsList.loadMore.classList.remove('show--');
                }
            } else {
                console.log('Error:', response.statusText);
            }
            NewsList.loader.classList.remove('show--');
        }).catch(function (error) {
            console.log('err', error);
            NewsList.loader.classList.remove('show--');
        });
    }
};

export default NewsList;