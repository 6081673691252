'use strict';

const GeoMap = {
    init: function () {
        const elts = document.querySelectorAll('.f-geoMap');
        if (elts.length > 0) {
            elts.forEach(elt => {
                GeoMap.showMap(elt);
                GeoMap.initInitialState(elt);
                GeoMap.initToggle(elt);
            });
        }
    },
    showMap: function (elt) {
        const buttons = elt.querySelectorAll('.f-geoMap__list button');
        const maps = elt.querySelectorAll('.f-geoMap__map');

        buttons.forEach(button => {
            button.addEventListener('click', function () {
                GeoMap.switchTab(elt, button);
            });

            button.addEventListener('keydown', function (e) {
                const key = e.which || e.keyCode;
                if (key === 37 || key === 39) {
                    e.preventDefault();
                    const index = Array.prototype.indexOf.call(buttons, this);
                    if (key === 37 && index > 0) { // Left arrow
                        buttons[index - 1].focus();
                    } else if (key === 39 && index < buttons.length - 1) { // Right arrow
                        buttons[index + 1].focus();
                    }
                }
            });
        });
    },
    switchTab: function (elt, button) {
        const buttons = elt.querySelectorAll('.f-geoMap__list button');
        const maps = elt.querySelectorAll('.f-geoMap__map');
        const country = button.getAttribute('data-country');

        buttons.forEach(btn => {
            btn.classList.remove('active--');
            btn.setAttribute('aria-selected', 'false');
            btn.setAttribute('tabindex', '-1');
        });

        button.classList.add('active--');
        button.setAttribute('aria-selected', 'true');
        button.setAttribute('tabindex', '0');

        maps.forEach(map => {
            map.classList.remove('show--');
            map.setAttribute('aria-hidden', 'true');
            map.setAttribute('tabindex', '-1');
        });

        const selectedMap = elt.querySelector(`#${country}`);
        if (selectedMap) {
            selectedMap.classList.add('show--');
            selectedMap.setAttribute('aria-hidden', 'false');
            selectedMap.setAttribute('tabindex', '0');
        }
    },
    initInitialState: function (elt) {
        const firstButton = elt.querySelector('.f-geoMap__list button');
        if (firstButton) {
            GeoMap.switchTab(elt, firstButton);
        }
    },
    initToggle: function (elt) {
        const toggles = elt.querySelectorAll('.f-geoMap__trans-toggle');
        
        toggles.forEach(toggle => {
            toggle.addEventListener('click', function () {
                const container = this.closest('.f-geoMap__trans');
                if (container) {
                    container.classList.toggle('show--');
                    // console.log(toggle, container.classList.contains('show--'))
                    toggle.querySelector('button').setAttribute('aria-expanded', container.classList.contains('show--'));
                }
            });
        });
    }
};

export default GeoMap;

