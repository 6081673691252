'use strict'

// A sample component
const WarningLink =  {
    els: [],
    txt:null,
    init: function(){
        WarningLink.els = document.querySelectorAll('a[target="_blank"]:not(.nopopin--)');
        WarningLink.txt = document.querySelector('.warning-popin').getAttribute('data-warning-text');
        if(WarningLink.txt)
            if(WarningLink.els.length){
                WarningLink.els.forEach( link => {
                    link.addEventListener('click', (e) => {
                        if(!window.confirm(WarningLink.txt)){
                            e.preventDefault()
                        }
                    })
                })
            }
    },
}


export default WarningLink;
