'use strict';

// core version + modules:
import Swiper, {Navigation, Pagination, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';

const PushNewsPress = {
    els: null,
    init: function () {
        PushNewsPress.els = document.querySelectorAll('.f-pushNewsPress');
        if (PushNewsPress.els && PushNewsPress.els.length > 0) {
            PushNewsPress.els.forEach(el => {
                if (window.matchMedia('(max-width: 1023px)').matches) {
                    PushNewsPress.create(el);
                }
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        let navigation = null;
        let pagination = null;
        swiper = new Swiper(slider, {
            modules: [Navigation, Pagination, A11y],
            a11y: true,
            slidesPerView: "auto",
            loop: false,
            speed: 600,
            autoHeight:false,
            navigation: {
                prevEl: ".f-slider__navigation-prev",
                nextEl: ".f-slider__navigation-next",
            },
            pagination: {
                el: ".f-slider__pagination",
                clickable: true,
                bulletClass: `f-slider__pagination-bullet swiper-pagination-bullet`
            }
        });

    },
};

export default PushNewsPress;